"use strict";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { EaselPlugin } from "gsap/EaselPlugin";
// import { CSSRulePlugin } from "gsap/CSSRulePlugin";
// gsap.registerPlugin(CSSRulePlugin);

export { gsap };
export { ScrollTrigger };
export { EaselPlugin };
// export { CSSRulePlugin };

export function init() {
  gsap.registerPlugin(ScrollTrigger, EaselPlugin);
}

// ランダムなdelayを付けたいときに
export function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min; //The maximum is inclusive and the minimum is inclusive
}
