"use strict";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { EaselPlugin } from "gsap/EaselPlugin";
import { CssCustomProperties } from "./utility/_cssCustomProperties";
// import { spanWrapText } from "./modules/_spanWrapText";
import * as modGsap from "./utility/_gsap";
gsap.registerPlugin(EaselPlugin, ScrollTrigger);
modGsap.init();
// new spanWrapText();
window.addEventListener("DOMContentLoaded", () => {
  new CssCustomProperties();
  // stepLetter();
  riseUpText();
  fadeText();
  // spanWrapText();
  animTrigger();
});
const headerButton = document.querySelector(".header-button");
headerButton.addEventListener("click", () => {
  headerButton.classList.toggle("is-active");
  document.body.classList.toggle("is-fixed");
  document.querySelector(".header").classList.toggle("is-active");
  document.querySelector(".header-nav").classList.toggle("is-active");
});
const spanWrapText = (target) => {
  const nodes = [...target.childNodes]; // ノードリストを配列にする
  let returnText = ""; // 最終的に返すテキスト

  for (const node of nodes) {
    if (node.nodeType == 3) {
      //テキストの場合
      const text = node.textContent.replace(/\r?\n/g, ""); //テキストから改行コード削除
      const splitText = text.split(""); // 一文字ずつ分割
      for (const char of splitText) {
        returnText += `<span>${char}</span>`; // spanタグで挟んで連結
      }
    } else {
      //テキスト以外の場合
      //<br>などテキスト以外の要素をそのまま連結
      returnText += node.outerHTML;
    }
  }
  return returnText;
};

const spanTexts = [...document.querySelectorAll(".js-text-span")];
for (const spanText of spanTexts) {
  spanText.innerHTML = spanWrapText(spanText);
  spanText.spans = spanText.querySelectorAll("span");
}

const riseUpTextSet = {
  duration: 6,
  display: "inline-block",
  overflow: "hidden",
  opacity: 0,
  y: 100,
  ease: "power3",
};
const riseUpTextTo = {
  opacity: 1,
  y: 0,
  stagger: 0.06,
};
function riseUpText() {
  gsap.utils.toArray(".anim-riseup-text").forEach((item) => {
    spanWrapText(item);
    const child = item.getElementsByTagName("span");
    gsap.set(child, riseUpTextSet);
    gsap.to(child, {
      ...riseUpTextTo,
      scrollTrigger: {
        // scroller: ".container__inner",
        trigger: item,
        start: "top 75%",
      },
    });
  });
}
function fadeText() {
  const fadeTextSet = {
    duration: 6,
    display: "inline-block",
    overflow: "hidden",
    opacity: 0,
    x: 50,
    ease: "power3",
  };
  const fadeTextTo = {
    opacity: 1,
    x: 0,
    stagger: 0.03,
  };
  gsap.utils.toArray(".anim-fade-text").forEach((item) => {
    spanWrapText(item);
    const child = item.getElementsByTagName("span");
    gsap.set(child, fadeTextSet);
    gsap.to(child, {
      ...fadeTextTo,
      scrollTrigger: {
        trigger: item,
        start: "top 75%",
      },
    });
  });
}
function stepLetter() {
  const stepLetter = document.querySelectorAll(".js-step-text");
  stepLetter.forEach((item, index) => {
    gsap.to(item, {
      scrollTrigger: {
        trigger: item,
        start: "top 85%",
        duration: 1,
        x: 0,
        opacity: 1,
        // ease: "power2.out",
        stagger: {
          from: "start",
          amount: 1,
        },
      },
    });
  });
}
function animTrigger() {
  // inview的アニメトリガー
  const animTarget = document.querySelectorAll(".animItem");
  animTarget.forEach((item, index) => {
    gsap.to(item, {
      scrollTrigger: {
        // scroller: ".container__main",
        trigger: item,
        start: "top 85%",
        once: true,
        // markers: true,
        toggleClass: {
          targets: item,
          className: "is-visible",
        },
        stagger: 0.3,
      },
    });
  });
}
