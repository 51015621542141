import debounce from "just-debounce-it";

export class CssCustomProperties {
  #currentWidth = document.body.clientWidth;
  constructor() {
    this.#setVw();
    this.#setSvh();

    window.addEventListener("resize", debounce(this.#resize, 500));
    // 確認用
    // window.addEventListener("resize", this.#resize);
  }
  #setVw = () => {
    const vw = document.body.clientWidth * 0.01;
    document.documentElement.style.setProperty("--vw", `${vw}px`);
  };
  #setSvh = () => {
    const svh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--svh", `${svh}px`);
  };
  #resize = () => {
    if (this.#currentWidth === document.body.clientWidth) {
      return;
    }
    this.#currentWidth = document.body.clientWidth;
    this.#setVw();
    this.#setSvh();
  };
}
